

export const minPriceList = {
  "sale": [
    {value: 0, text: "No Min"},
    {value: 50000, text: "US$ 50,000"},
    {value: 75000, text: "US$ 75,000"},
    {value: 100000, text: "US$ 100,000"},
    {value: 125000, text: "US$ 125,000"},
    {value: 150000, text: "US$ 150,000"},
    {value: 175000, text: "US$ 175,000"},
    {value: 200000, text: "US$ 200,000"},
    {value: 225000, text: "US$ 225,000"},
    {value: 250000, text: "US$ 250,000"},
    {value: 275000, text: "US$ 275,000"},
    {value: 300000, text: "US$ 300,000"},
    {value: 350000, text: "US$ 350,000"},
    {value: 400000, text: "US$ 400,000"},
    {value: 450000, text: "US$ 450,000"},
    {value: 500000, text: "US$ 500,000"},
    {value: 550000, text: "US$ 550,000"},
    {value: 600000, text: "US$ 600,000"},
    {value: 6500000, text: "US$ 650,000"},
    {value: 700000, text: "US$ 700,000"},
    {value: 750000, text: "US$ 750,000"},
    {value: 800000, text: "US$ 800,000"},
    {value: 850000, text: "US$ 850,000"},
    {value: 900000, text: "US$ 900,000"},
    {value: 950000, text: "US$ 950,000"},
    {value: 1000000, text: "US$ 1,000,000"}
  ],
  "rent": [
    {value: 0, text: "No Min"},
    {value: 500, text: "US$500"},
    {value: 750, text: "US$750"},
    {value: 1000, text: "US$ 1,000"},
    {value: 1250, text: "US$ 1,250"},
    {value: 1500, text: "US$ 1,500"},
    {value: 1750, text: "US$ 1,750"},
    {value: 2000, text: "US$ 2,000"},
    {value: 2250, text: "US$ 2,250"},
    {value: 2500, text: "US$ 2,500"},
    {value: 2750, text: "US$ 2,750"},
    {value: 3000, text: "US$ 3,000"},
    {value: 3250, text: "US$ 3,250"},
    {value: 3500, text: "US$ 3,500"},
    {value: 3750, text: "US$ 3,750"},
    {value: 4000, text: "US$ 4,000"},
    {value: 4250, text: "US$ 4,250"},
    {value: 4500, text: "US$ 4,500"},
    {value: 4750, text: "US$ 4,750"},
    {value: 5000, text: "US$ 5,000"}
  ]
}

export const maxPriceList = {
  "sale": [
    {value: 2000000, text: "No Max"},
    {value: 50000, text: "US$ 50,000"},
    {value: 75000, text: "US$ 75,000"},
    {value: 100000, text: "US$ 100,000"},
    {value: 125000, text: "US$ 125,000"},
    {value: 150000, text: "US$ 150,000"},
    {value: 175000, text: "US$ 175,000"},
    {value: 200000, text: "US$ 200,000"},
    {value: 225000, text: "US$ 225,000"},
    {value: 250000, text: "US$ 250,000"},
    {value: 275000, text: "US$ 275,000"},
    {value: 300000, text: "US$ 300,000"},
    {value: 350000, text: "US$ 350,000"},
    {value: 400000, text: "US$ 400,000"},
    {value: 450000, text: "US$ 450,000"},
    {value: 500000, text: "US$ 500,000"},
    {value: 550000, text: "US$ 550,000"},
    {value: 600000, text: "US$ 600,000"},
    {value: 6500000, text: "US$ 650,000"},
    {value: 700000, text: "US$ 700,000"},
    {value: 750000, text: "US$ 750,000"},
    {value: 800000, text: "US$ 800,000"},
    {value: 850000, text: "US$ 850,000"},
    {value: 900000, text: "US$ 900,000"},
    {value: 950000, text: "US$ 950,000"},
    {value: 1000000, text: "US$ 1,000,000"}
  ],
  "rent": [
    {value: 0, text: "No Max"},
    {value: 500, text: "US$500"},
    {value: 750, text: "US$750"},
    {value: 1000, text: "US$ 1,000"},
    {value: 1250, text: "US$ 1,250"},
    {value: 1500, text: "US$ 1,500"},
    {value: 1750, text: "US$ 1,750"},
    {value: 2000, text: "US$ 2,000"},
    {value: 2250, text: "US$ 2,250"},
    {value: 2500, text: "US$ 2,500"},
    {value: 2750, text: "US$ 2,750"},
    {value: 3000, text: "US$ 3,000"},
    {value: 3250, text: "US$ 3,250"},
    {value: 3500, text: "US$ 3,500"},
    {value: 3750, text: "US$ 3,750"},
    {value: 4000, text: "US$ 4,000"},
    {value: 4250, text: "US$ 4,250"},
    {value: 4500, text: "US$ 4,500"},
    {value: 4750, text: "US$ 4,750"},
    {value: 5000, text: "US$ 5,000"}
  ]
}